// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/shared/error/NotFound.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/shared/error/NotFound.tsx");
  import.meta.hot.lastModified = "1731188947256.7112";
}
// REMIX HMR END

import { Box, Button, Stack, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { paperStyles, borderBoxShadow } from '~/common/commonStyles';
import { beating } from '~/common/Animations/Animations';
import { useNavigate, useNavigation } from '@remix-run/react';
import { useViewContext } from '~/context/ViewContext';
export default function NotFound({
  isInsideHome
}) {
  _s();
  const navigate = useNavigate();
  const navigation = useNavigation();
  const {
    headerHeight
  } = useViewContext();
  return <Stack height={isInsideHome ? `calc(100vh - ${headerHeight}px)` : "100vh"} width="100%" flex={1} direction="column" justifyContent="center" alignItems="center" sx={{
    background: isInsideHome ? "transparent" : "linear-gradient(to right, #e7ebef, #f1f1f1)"
  }}>

            <Stack sx={{
      ...paperStyles,
      minHeight: "50vh",
      p: 4
    }} direction="column" alignItems="center" spacing={6}>
                <Box sx={{
        animation: `${beating} 4s infinite both`
      }}>
                    <ErrorIcon sx={{
          fontSize: 160
        }} color="primary" />
                </Box>
                <Box textAlign="center">
                    <Typography variant="h4" component="h1" children="Error 404" color="primary" />
                    <Typography variant="h5" children="Lo sentimos. La página a la que intentas acceder no existe." color="textSecondary" mt={2} />
                </Box>
                {!isInsideHome && <Button variant="contained" size='large' fullWidth color="warning" children="volver al inicio" onClick={() => navigate("/home")} disabled={navigation.state === "submitting" || navigation.state === "loading"} sx={borderBoxShadow} aria-label="Volver al inicio" />}
            </Stack>
        </Stack>;
}
_s(NotFound, "3M5tzL0XBo5U7w9Vf/ceYTEFY7I=", false, function () {
  return [useNavigate, useNavigation, useViewContext];
});
_c = NotFound;
var _c;
$RefreshReg$(_c, "NotFound");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;